@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import './material/angular-material';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@import './material/material-overrides';

@import '~quill/dist/quill.snow.css';

@import "./angular2-smart-table";

@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';

// install the framework and custom global styles
@include nb-install() {

  @include nb-tables-smart-table-theme();

  @include angular-material();

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
  @include material-overrides();

  .ql-toolbar.ql-snow {
    background-color: nb-theme(input-basic-background-color);
    border-top-left-radius: nb-theme(input-rectangle-border-radius);
    border-top-right-radius: nb-theme(input-rectangle-border-radius);
    border-color: nb-theme(input-basic-border-color);
  }

  .ql-container.ql-snow {
    background-color:nb-theme(input-basic-background-color);
    border-bottom-left-radius: nb-theme(input-rectangle-border-radius);
    border-bottom-right-radius: nb-theme(input-rectangle-border-radius);
    border-color: nb-theme(input-basic-border-color);
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
  }

  .c-btn {
    background: nb-theme(input-basic-background-color);
    border: 1px solid nb-theme(input-basic-border-color);
    color: nb-theme(text-basic-color);
  }

  .selected-list .c-list .c-token {
    background: nb-theme(background-primary-color-1); }
  .selected-list .c-list .c-token .c-label {
    color: #fff; }
  .selected-list .c-list .c-token .c-remove svg {
    fill: #fff; }

  .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
    fill: #333; }

  .dropdown-list ul li:hover {
    background: nb-theme(select-outline-basic-hover-background-color);
    border-color: nb-theme(select-outline-basic-hover-border-color);
  }

  .dropdown-list {
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
  }

  .arrow-up, .arrow-down {
    border-bottom: 15px solid nb-theme(select-outline-basic-background-color); }

  .arrow-2 {
    border-bottom: 15px solid nb-theme(select-outline-basic-hover-border-color); }

  .list-area {
    border: 1px solid nb-theme(select-outline-basic-border-color);
    background: nb-theme(select-outline-basic-background-color);
  }

  .select-all {
    border-bottom: 1px solid nb-theme(border-alternative-color-5);
  }
  .list-filter input {
    color: nb-theme(text-basic-color);
    background: nb-theme(select-outline-basic-background-color);
  }
  .list-filter {
    border-bottom: 1px solid nb-theme(border-alternative-color-5);
  }
  .list-filter .c-search svg {
    fill: nb-theme(text-basic-color)
  }
  .list-filter .c-clear svg {
    fill: nb-theme(text-basic-color)
  }

  .pure-checkbox input[type="checkbox"]:focus + label:before, .pure-checkbox input[type="checkbox"]:hover + label:before {
    border-color: nb-theme(background-primary-color-1);
    background-color: #f2f2f2; }

  .pure-checkbox input[type="checkbox"] + label {
    color: nb-theme(text-basic-color); }

  .pure-checkbox input[type="checkbox"] + label:before {
    color: nb-theme(background-primary-color-1);
    border: 1px solid nb-theme(background-primary-color-1); }

  .pure-checkbox input[type="checkbox"] + label:after {
    background-color: nb-theme(background-primary-color-1); }

  .pure-checkbox input[type="checkbox"]:disabled + label:before {
    border-color: #cccccc; }

  .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
    background-color: #cccccc; }

  .pure-checkbox input[type="checkbox"] + label:after {
    border-color: #ffffff; }

  .pure-checkbox input[type="radio"]:checked + label:before {
    background-color: white; }

  .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: nb-theme(background-primary-color-1); }

  .single-select-mode .pure-checkbox input[type="checkbox"]:focus + label:before, .single-select-mode .pure-checkbox input[type="checkbox"]:hover + label:before {
    border-color: nb-theme(background-primary-color-1);
    background-color: #f2f2f2; }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label {
    color: nb-theme(text-basic-color); }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:before {
    color: transparent !important;
    border: 0px solid nb-theme(background-primary-color-1); }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    background-color: transparent !important; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:disabled + label:before {
    border-color: #cccccc; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
    background-color: #cccccc; }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    border-color: nb-theme(background-primary-color-1); }

  .single-select-mode .pure-checkbox input[type="radio"]:checked + label:before {
    background-color: white; }

  .single-select-mode .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: none !important; }

  .selected-item {
    background: rgba(51, 102, 255, 0.15);
  }

  .btn-iceblue {
    background: nb-theme(background-primary-color-1);
    border: 1px solid #ccc;
    color: #fff; }

  // modal window form
  %window-base {
    overflow: auto auto;
    max-width: 95vw;
    max-height: 95vh;
  }
  .window-form {
    @extend %window-base;
    width: 600px;
  }

  .window-form-wide {
    @extend %window-base;
    width: 800px;
  }

  .window-form-ultra-wide {
    @extend %window-base;
    width: 1200px;
  }

  .dialog-form {
    width: 600px;
    display: block;
  }

  // pager style adjusting
  #per-page {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  nav.angular2-smart-pagination-per-page {
    display: flex;
    justify-content: center;
  }

  nav.angular2-smart-pagination-per-page label {
    display: none;
  }

  nav.angular2-smart-pagination-nav {
    margin-top: 0 !important;
  }

  angular2-smart-table-pager {
    display: flex !important;
    justify-content: center !important;
  }

  ul.angular2-smart-pagination.pagination {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }


  // custom icon style adjusting
  angular2-smart-table .angular2-smart-actions {
    height: auto !important;

    .angular2-smart-action-custom-custom i {
      font-size: 1.4rem;
      color: #8a8a8a;
      margin: 1rem;
    }

    i.fa-eye:hover {
      color: #4343fc;
    }

    i.fa-trash-alt:hover {
      color: #fc435f;
    }

    i.fa-undo-alt:hover {
      color: #e7bc13;
    }

    i.fa-play-circle:hover {
      color: rgb(45, 190, 14);
    }

    i.fa-file-download:hover {
      color: rgb(14, 70, 190);
    }

    i.fa-copy:hover {
      color: rgb(45, 190, 14);
    }

    i.fa-vial:hover {
      color: rgb(67, 217, 37);
    }

    i.fa-pencil-alt:hover {
      color: #4343fc;
    }

    i.fa-file-export:hover {
      color: #4343fc;
    }

    i.fa-check:hover {
      color: rgb(45, 190, 14);
    }

    i.fa-ban:hover {
      color: #e81111;
    }

    i.fa-clock:hover {
      color: #dabb00;
    }


    i.fa-cc-visa:hover {
      color: #0ba620;
    }

    i.fa-box:hover {
      color: rgb(217, 181, 37);
    }

    i.fa-box-open:hover {
      color: rgb(217, 181, 37);
    }

    i.fa-phone-alt:hover {
      color: #4343fc;
    }

    i.fa-play:hover {
      color: #0ba620;
    }

    i.fa-pause:hover {
      color: rgb(217, 181, 37);
    }

    angular2-st-tbody-custom {
      display: flex;
    }
  }


  // detail header styling
  .detail-header {
    display: flex;
    justify-content: space-between;

    a {
      padding-right: 1rem;
      margin-right: 1rem;
      font-size: 2rem;
      border-right: 1px solid #dedede;
      cursor: pointer;
    }

    nb-icon {
      font-size: 2rem;
    }

    .title {
      vertical-align: super;
    }
  }

  // custom styles for hiding specific columns inside angular2-smart-table component
  .hd-cl-0 th:nth-child(1), .hd-cl-0 td:nth-child(1),
  .hd-cl-1 th:nth-child(2), .hd-cl-1 td:nth-child(2),
  .hd-cl-2 th:nth-child(3), .hd-cl-2 td:nth-child(3),
  .hd-cl-3 th:nth-child(4), .hd-cl-3 td:nth-child(4),
  .hd-cl-4 th:nth-child(5), .hd-cl-4 td:nth-child(5),
  .hd-cl-5 th:nth-child(6), .hd-cl-5 td:nth-child(6),
  .hd-cl-6 th:nth-child(7), .hd-cl-6 td:nth-child(7),
  .hd-cl-7 th:nth-child(8), .hd-cl-7 td:nth-child(8),
  .hd-cl-8 th:nth-child(9), .hd-cl-8 td:nth-child(9),
  .hd-cl-9 th:nth-child(10), .hd-cl-9 td:nth-child(10),
  .hd-cl-10 th:nth-child(11), .hd-cl-10 td:nth-child(11),
  .hd-cl-11 th:nth-child(12), .hd-cl-11 td:nth-child(12),
  .hd-cl-12 th:nth-child(13), .hd-cl-12 td:nth-child(13),
  .hd-cl-13 th:nth-child(14), .hd-cl-13 td:nth-child(14),
  .hd-cl-14 th:nth-child(15), .hd-cl-14 td:nth-child(15),
  .hd-cl-15 th:nth-child(16), .hd-cl-15 td:nth-child(16),
  .hd-cl-16 th:nth-child(17), .hd-cl-16 td:nth-child(17),
  .hd-cl-17 th:nth-child(18), .hd-cl-17 td:nth-child(18),
  .hd-cl-18 th:nth-child(19), .hd-cl-18 td:nth-child(19),
  .hd-cl-19 th:nth-child(20), .hd-cl-19 td:nth-child(20),
  .hd-cl-20 th:nth-child(21), .hd-cl-20 td:nth-child(21),
  .hd-cl-21 th:nth-child(22), .hd-cl-21 td:nth-child(22),
  .hd-cl-22 th:nth-child(23), .hd-cl-22 td:nth-child(23),
  .hd-cl-23 th:nth-child(24), .hd-cl-23 td:nth-child(24),
  .hd-cl-24 th:nth-child(25), .hd-cl-24 td:nth-child(25),
  .hd-cl-25 th:nth-child(26), .hd-cl-25 td:nth-child(26),
  .hd-cl-26 th:nth-child(27), .hd-cl-26 td:nth-child(27),
  .hd-cl-27 th:nth-child(28), .hd-cl-27 td:nth-child(28),
  .hd-cl-28 th:nth-child(29), .hd-cl-28 td:nth-child(29),
  .hd-cl-29 th:nth-child(30), .hd-cl-29 td:nth-child(30),
  .hd-cl-30 th:nth-child(31), .hd-cl-30 td:nth-child(31),
  .hd-cl-31 th:nth-child(32), .hd-cl-31 td:nth-child(32),
  .hd-cl-32 th:nth-child(33), .hd-cl-32 td:nth-child(33),
  .hd-cl-33 th:nth-child(34), .hd-cl-33 td:nth-child(34),
  .hd-cl-34 th:nth-child(35), .hd-cl-34 td:nth-child(35),
  .hd-cl-35 th:nth-child(36), .hd-cl-35 td:nth-child(36),
  .hd-cl-36 th:nth-child(37), .hd-cl-36 td:nth-child(37),
  .hd-cl-37 th:nth-child(38), .hd-cl-37 td:nth-child(38),
  .hd-cl-38 th:nth-child(39), .hd-cl-38 td:nth-child(39),
  .hd-cl-39 th:nth-child(40), .hd-cl-39 td:nth-child(40),
  {
    display: none;
  }

  .display-reset-button .reset-button {
    display: inline-block !important;
  }

  .display-archive-button .archive-button {
    display: inline-block !important;
  }

  .display-unarchive-button .unarchive-button {
    display: inline-block !important;
  }

  a:has(i.hideable-button) {
    display: none !important;
  }

  tr.display-archive-button a:has(i.archive-button ) {
    display: inherit !important;
  }

  tr.display-unarchive-button a:has(i.unarchive-button) {
    display: inherit !important;
  }

  .display-accept-button .accept-button {
    display: inline-block !important;
  }

  .display-deny-button .deny-button {
    display: inline-block !important;
  }

  .display-edit-button .edit-button {
    display: inline-block !important;
  }

  .display-pause-button .pause-button {
    display: inline-block !important;
  }

  .display-phone-button .phone-button {
    display: inline-block !important;
  }

  .display-activate-button .activate-button {
    display: inline-block !important;
  }

  .display-deactivate-button .deactivate-button {
    display: inline-block !important;
  }

  .dragged {
    opacity: 0.4;
  }

  .dragged-over {
    background: #def0ff !important;
  }

  .popper-handle {
    width: 20px;
    height: 20px;
    background: red;
    border-radius: 20px;
    z-index: 1030;
  }
};

.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1025;
}
